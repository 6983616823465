<template>
    <div class="desktopLayoutClass">
      <v-app>
        <div v-if="error_enable">
          <v-row justify="center">
            <v-dialog v-model="error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                  {{$t("Warning_Message.Something_Went_Wrong")}}
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed
                    color="primary" @click="closeDialogError" style="background-color: #1467BF;">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
       </div>

        <!-- Header Start -->
        <div v-show="show_header" class="d-flex header" style="padding-top: 10px; padding-left: 5px;">
          <div class="d-flex" style="width: 100%">
              <div style="width: 10%; padding-top: 6px" align="left" v-on:click="redirectToHomePage">
                  <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
              </div>

              <div style="width: 90%" align="left">
                  <span class="headertext">{{ $t("Customer.ViewDoctorsPage.View_Doctors") }}</span>
              </div>
          </div>
        </div>
        <!-- Header End -->

        <div v-if="isLoading" class="mt-1">
            <div>
                <v-skeleton-loader type="list-item-two-line" width="300px"></v-skeleton-loader>
            </div>
            
            <div class="pa-2" v-for="i in [1,2,3,4,5]" :key="i">
                <v-card elevation="2" height="80px">
                    <div class="d-flex justify-start pa-1">
                        <div class="d-flex justify-center align-center pa-1">
                            <v-skeleton-loader type="image" width="60px" height="60px"></v-skeleton-loader>
                        </div>

                        <div class="d-flex flex-column pa-1">
                            <!-- <div class="py-1">
                                <v-skeleton-loader type="text" width="180px"></v-skeleton-loader>
                            </div> -->

                            <div class="py-1">
                                <v-skeleton-loader type="text" width="180px"></v-skeleton-loader>
                            </div>

                            <div class="py-1">
                                <v-skeleton-loader type="text" width="180px"></v-skeleton-loader>
                            </div>
                        </div>

                        <v-skeleton-loader class="mb-0 mr-0" style="position: absolute; right: 10px; bottom: 10px;" type="button"></v-skeleton-loader>

                    </div>
                </v-card>
            </div>
        </div>
        <div v-else>
            <div v-if="spec_desc.length > 0" style="display:flex; flex-direction: row; flex-wrap: wrap;justify-content: space-evenly; ">
              <img class="imgTag" :src=spec_url>
            </div>
            <div v-if="spec_url.length > 0" style="display:flex; flex-direction: row; flex-wrap: wrap;justify-content: space-evenly;  padding: 20px 10px;">
                <p align="justify" style="margin-bottom: 0px !important; font-size: 0.9em !important; line-height: 1.7em !important; color: black">{{spec_desc}}</p>
            </div>
            <div v-if="doctor_list.length > 0" style="margin-top:10px; display:flex; flex-direction: column; flex-wrap: wrap; justify-content:space-evenly;  padding-bottom:20px;">
                <div
                    v-for="doctor in doctor_list"
                        :key="doctor._id"
                        style="padding-left:15px; padding-right:15px; padding-top:10px; "
                    >
                    <doctorsComponent :doctor="doctor" :specName="specName" :walkin="walkin" :pageName="doctorType"></doctorsComponent>
                </div>
            </div>
            <div v-else>
                <h3 style="margin-top:10%">{{ $t("Customer.ViewDoctorsPage.No_Doctors_Found") }}</h3>
            </div>
        </div>
      </v-app>
    </div>
</template>
<script>
import axios from "axios";
import doctorsComponent from "../../../components/doctorsComponent.vue";
export default{
    name: "viewDoctors",
    data(){
        return{
          isActive: false,
          doctor_list:[],
          doctorDetails:"",
          isLoggedIn: false,
          pageName: "View Doctors",
          isLoading: true,
          error_enable: false,
          show_header:true,
          banner_src: false,
          walkin: "",
          spec_desc: "",
          spec_url: "",
          specName: null,
          doctorType: null
        }
    },
    components: {
      doctorsComponent,
    },
    mounted(){
      if(this.$route.query.type === 'ServiceDoctors') {
        this.doctorType = 'viewDoctors_serviceDoctors';
        localStorage.setItem('bookingType', 'oldServiceAppointment');
      } else if (this.$route.query.type === 'Walkin') {
        this.doctorType = 'viewDoctors_walkin'
        localStorage.setItem('bookingType', 'walkinAppointment');
      } else if (this.$route.query.type === 'SpecPromotion') {
        this.doctorType = 'viewDoctors_specPromotion'
        localStorage.setItem('bookingType', 'featureSpecialityAppointment');
      } else if (this.$route.query.type === 'Category') {
        this.doctorType = 'viewDoctors_category'
        localStorage.setItem('bookingType', 'regularAppointment');
      }
      let userAgent = navigator.userAgent;
      var container = document.querySelector(".desktopLayoutClass");
      if(userAgent.includes("kbzpay")){
        this.show_header = false;
      }
      if(this.$store.state.locale == "en")
        document.title = "Doctors List"
      else
        document.title = "ဆရာဝန်စာရင်း"

      let todaysDate = new Date().toISOString();
      todaysDate = todaysDate.split("T")[0]
      if(this.$store.state.locale !== "")
      {
        this.$i18n.locale =  this.$store.state.locale;
      }
      else
      {
        this.$i18n.locale = "mm";
        this.$store.dispatch("changeLocale", this.$i18n.locale);
      }
      this.currentCustomer = this.$cookies.get("customerToken");
      var access = this.$cookies.get("access");
      var lang = this.$i18n.locale;
      var params_records = this.$route.query;
      var paramsType = params_records.type;
      if(paramsType === 'Walkin'){
        this.walkin = "Walkin"
      }
      var paramsType1 = params_records.type1;
      this.specName = params_records.type2;
      var getAdminBody ={
          token : this.currentUser,
          typeOfUser : "ADMIN",
      }
      if (!this.currentCustomer) {
        this.isLoading = true;
        this.isLoggedIn = false;
        var readListOfWalkinDoctor = {
          // dateOfWalkIn:new Date().toISOString().split("T")[0]
          paramsType: paramsType,
          paramsType1: paramsType1
        };
        axios
        .post(
            process.env.VUE_APP_BACKEND_URL + "/listOfDoctorsNonLogin",readListOfWalkinDoctor
                ).then((getAllWalkInDoctorsResponse) => {
              this.doctor_list = getAllWalkInDoctorsResponse.data.doctorsRecords;
              if(getAllWalkInDoctorsResponse.data.spec_description && getAllWalkInDoctorsResponse.data.spec_url){
                this.spec_desc = getAllWalkInDoctorsResponse.data.spec_description;
                this.spec_url = getAllWalkInDoctorsResponse.data.spec_url;
              }
            this.isLoading = false;
            this.userPageViewTable();
        })
        .catch((getAllWalkInDoctorsError) => {
          this.isLoading = false;
          this.error_enable = true;
          console.log(getAllWalkInDoctorsError);
        });
      }
      else
      {

        //logging page hit - start
        let x =  localStorage.getItem("currentURL")
        let pageName = "ListofSpecDoctorsScreen";
        if( x == pageName)
          x = "";
        localStorage.setItem("currentURL", pageName)
        var pageAccessBody = {
          token: this.currentCustomer,
          pageName: pageName,
          previousPage: x ? x : "START"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
        .then((logSuccess) =>{

        })
        .catch((logError) =>{

        });
        //logging page hit - end

        this.isLoading = true;
        var readListOfWalkinDoctor = {
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
          paramsType: paramsType,
          paramsType1: paramsType1,
          // dateOfWalkIn:new Date().toISOString().split("T")[0]
        };
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/listOfDoctors",readListOfWalkinDoctor
              ).then((getAllWalkInDoctorsResponse) => {
            this.isLoggedIn = true;
            this.doctor_list = getAllWalkInDoctorsResponse.data.doctorsRecords;
            if(getAllWalkInDoctorsResponse.data.spec_description && getAllWalkInDoctorsResponse.data.spec_url){
              this.spec_desc = getAllWalkInDoctorsResponse.data.spec_description;
              this.spec_url = getAllWalkInDoctorsResponse.data.spec_url;
            }
          this.isLoading = false;
          this.userPageViewTable();
        })
        .catch((getAllWalkInDoctorsError) => {
          this.isLoading = false;
          this.error_enable = true;
          console.log(getAllWalkInDoctorsError);
        });
      }
    },
    methods:{
      redirectToNewDoctorsPage(docId) {
        this.$router.push({
            name: 'newBookingPage',
            params: { docId: docId }
        });
      },
      closeDialogError(){
        this.error_enable = false;
      },
      alternate(obj){
        obj.selectedClass =  obj.selectedClass === 'defaultClass' ? 'activeClass' : 'defaultClass'
        this.$router.push({
          path: "/customer/specBookAppointment/" + obj._id
        });
      },
      redirectToHomePage(){
        this.$router.go(-1);
      },
      userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'viewDoctors',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'viewDoctors',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
      },
    }
}
</script>
<style scoped>
.imgTag{
  width: 95%;
  height: auto;
  border-radius: 8px;
}
.activeClass {
  background-color: #1467BF !important;
  color: white !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}
.defaultClass {
  background-color: white !important;
  color: black !important;
  opacity: 80% !important;
  padding: 0px !important;
  margin: 0px !important;

}
.specialistName{     
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #828282;
}
.headertext{
    font-size: 20px;
    font-weight: bold;
    font-family: Inter;
}
</style>