import { render, staticRenderFns } from "./doctorsComponent.vue?vue&type=template&id=17646972&scoped=true&"
import script from "./doctorsComponent.vue?vue&type=script&lang=js&"
export * from "./doctorsComponent.vue?vue&type=script&lang=js&"
import style0 from "./doctorsComponent.vue?vue&type=style&index=0&id=17646972&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17646972",
  null
  
)

export default component.exports